import Profile from "./Profile";

class Current {

    /**
     * @private
     * @static
     * @type {Profile}
     */
    static DEV = Profile.of('DEV', true, "http://localhost:8080", true);

    /**
     * @private
     * @static
     * @type {Profile}
     */
    static SANDBOX = Profile.of('SANDBOX', true, "https://api.ewaluacja.online", true);

    /**
     * @private
     * @static
     * @type {Profile}
     */
    static PRODUCTION = Profile.of('PRODUCTION', false, "https://api.ewaluacja.online", true);

    /**
     * @public
     * @static
     * @type {Profile}
     */
    static PROFILE = Current.PRODUCTION;

}

export default Current;