import React, {Component} from 'react';
import {connect} from "react-redux";
import State from "../../store/State";
import PageLoginUser from "../PageLoginUser";
import ApiEngine from "../../logic/ApiEngine";
import SetActiveMenuItemAction from "../../store/actions/profile/SetActiveMenuItemAction";
import Msg from "../../i18n/Msg";
import ScrollToTopOnMount from "../../components/helpers/ScrollToTopOnMount";
import Preloader from "../../components/visual/Preloader";
import Modal from "../../components/visual/Modal";
import ModalHandler from "../../components/visual/ModalHandler";
import Button from "../../components/visual/Button";
import Field from "../../form/components/Field";
import ValidationResult from "../../logic/ValidationResult";
import SurveyParamsForm from "../../form/SurveyParamsForm";
import Logger from "../../logic/Logger";
import Row from "../../components/website/Row";
import Col from "../../components/website/Col";
import Text from "../../components/website/Text";
import Section from "../../components/website/Section";
import Subpage from "../../components/website/Subpage";
import {withRouter} from "react-router-dom";
import UpdateQuestsItemsCols from "../../store/actions/Survey/UpdateQuestsItemsCols";
import UpdateStats from "../../store/actions/Survey/UpdateStats";
import BarChart from "../../components/charts/BarChart";
import {jsPDF} from 'jspdf';
import ChartData from "../../components/charts/ChartData";
import ScrollToMeOnRender from "../../components/helpers/ScrollToMeOnRender";

class PageSurveys extends Component {

    barChartRef = React.createRef();
    statsLoadingProgress = false;

    state = {
        surveys: [],
        surveysInitialized: false,
        surveyParamsMap: {},
        validationResult: ValidationResult.get(),
        surveyDetailsExpanded: {},
        runningSurveyPreloader: false,
        quotes: {},
        lastQuotesLoadTime: 0,
        statsDataLoaded: false,
        chartDataCollection: {},
        statsModalElement: null,
        chartIndexShown: 0,
        chartNavigationExpanded: false
    }

    /**
     * @param {object} e zdarzenie
     * @param {string} srwID nazwa obiektu w state
     */
    handleSurveyParamsState = (e, srwID) => {
        e.persist();
        this.setState(prevState => {
            const fromEvent = Field.readFromEvent(e);
            const newState = prevState;
            newState.surveyParamsMap[srwID][fromEvent.fieldName] = Number(fromEvent.fieldValue);
            return newState;
        })
    }

    /**
     * @description funkcja generująca funkcje handle dla formularza
     * @param srwID
     * @return {function}
     */
    generatorOfHandleSurveyParamsChangeFunction = srwID => {
        const handleFunc = e => {
            this.handleSurveyParamsState(e, srwID);
        }
        return handleFunc;
    }

    componentDidMount() {
        if (!this.state.surveysInitialized) {
            this.loadSurveyList();
        }
        this.loadQuotes();
        /*this.loadSurveyList();*/
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.surveysInitialized) {
            this.loadSurveyList();
        }
        this.loadQuotes();
        const profileState = State.getProfileState(this.props);
        SetActiveMenuItemAction.run(this.props.dispatch, profileState.activeMenuItem, Msg.of().menu.surveys.label);
    }


    /**
     *
     * @param {Survey[]} list1
     * @param {Survey[]} list2
     * @return {boolean}
     */
    areSurveyListsEqual = (list1, list2) => {
        let result = false;
        if (!list1 && !list2) {
            result = true;
        } else if (list1 && list2) {
            if (list1.length && list2.length && list1.length === list2.length) {
                result = true;
                let oneElementDifferent = false;
                for (let i = 0; i < list1.length; i++) {
                    if (!list1[i].equals(list2[i])) {
                        oneElementDifferent = true;
                        break;
                    }
                }
                if (oneElementDifferent) result = false;
            }
        }
        return result;
    }


    /**
     * @param {Survey[]} list1
     * @param {Survey[]} list2
     * @return {boolean}
     */
    areSurveyListsDifferent = (list1, list2) => {
        return !this.areSurveyListsEqual(list1, list2);
    }


    loadSurveyList = () => {
        const profileState = State.getProfileState(this.props);
        ApiEngine.surveyListAll(
            /**
             *
             * @param {Survey[]} surveys
             */
            surveys => {
                if (this.areSurveyListsDifferent(surveys, this.state.surveys) || !this.state.surveysInitialized) {
                    /* ApiEngine.surveyParamsList(
                         /!**
                          *
                          * @param {SurveyParams[]} surveyParamsTab
                          *!/
                         surveyParamsTab => {
                             const surveyParamsMap = {}
                             surveys.forEach(survey => {
                                 surveyParamsMap[survey.srwID] = surveyParamsTab.find(surveyParams => surveyParams.srwID === survey.srwID);
                                 if (!surveyParamsMap[survey.srwID]) {
                                     surveyParamsMap[survey.srwID] = SurveyParams.getDefault(survey.srwID, profileState.eduInstitution.id, 100);
                                 }
                             });
                             Logger.info(ApiEngine.SCHOOL_SIZES);
                             this.setState(prevState => {
                                 return {...prevState, surveys, surveysInitialized: true, surveyParamsMap};
                             })
                         }, () => {
                         });*/

                    const surveyParamsMap = {};
                    const surveyDetailsExpanded = {}
                    let oneWasExpanded = false;
                    surveys.forEach(survey => {
                        if (survey.params) {
                            surveyParamsMap[survey.srwID] = survey.params;
                        }
                        if (!oneWasExpanded) {
                            oneWasExpanded = true;
                            surveyDetailsExpanded[survey.srwID] = true;
                        } else {
                            surveyDetailsExpanded[survey.srwID] = false;
                        }
                    })

                    this.setState(prevState => {
                        return {...prevState, surveys, surveysInitialized: true, surveyParamsMap, surveyDetailsExpanded};
                    })
                }
            }, () => {
                /* this.setState(prevState => {
                     return {...prevState, surveysInitialized: false};
                 })*/
            });
    }

    saveSurveyParamsAction = (srwID, modalID) => {
        this.setState({runningSurveyPreloader: true});
        ApiEngine.setSurveyParams(this.state.surveyParamsMap[srwID],
            (savedSurveyParams) => {
                const surveyParamsMap = {...this.state.surveyParamsMap};
                surveyParamsMap[srwID] = savedSurveyParams;
                this.setState({surveyParamsMap, runningSurveyPreloader: false});
                ModalHandler.of(modalID).hide();
            }, (response) => {
                Logger.error(response);
            });
    }

    runSurvey = (srwID, modalID) => {
        this.setState({runningSurveyPreloader: true});
        ApiEngine.setSurveyParamsDefault(srwID,
            (savedSurveyParams) => {
                const surveyParamsMap = {...this.state.surveyParamsMap};
                const surveys = this.state.surveys.map(
                    /**
                     *
                     * @param {Survey} survey
                     * @return {Survey}
                     */
                    survey => {
                        if (survey.srwID === srwID) {
                            survey.params = savedSurveyParams;
                        }
                        return survey;
                    });
                surveyParamsMap[srwID] = savedSurveyParams;
                ModalHandler.of(modalID).hide();
                this.setState({surveys, surveyParamsMap, runningSurveyPreloader: false});
            }, (response) => {
                Logger.error(response);
            });
        this.loadQuotes();
    }

    generateEditingParamsModal = (modalID, srwID, surveyParams, openingButtonLabel, saveButtonLabel) => {
        const modal = (
            <Modal id={modalID} showModalButtonText={openingButtonLabel} title="Parametry badania" showModalButtonWidth="11rem"
                   buttonPrimary={saveButtonLabel} buttonPrimaryAction={() => {
                this.saveSurveyParamsAction(srwID, modalID)
            }}>
                <>
                    {SurveyParamsForm.get(surveyParams, 500, this.generatorOfHandleSurveyParamsChangeFunction(srwID), this.state.validationResult)}
                </>
            </Modal>
        );
        return modal;
    }

    generateRunSurveyModal = (modalID, srwID) => {
        const modal = (
            <Modal id={modalID} showModalButtonText="Generuj link" title="Aktywowanie ankiety" showModalButtonWidth="11rem" buttonPrimary="Generuj link"
                   buttonSecondary="Anuluj"
                   buttonSecondaryAction={() => {
                       ModalHandler.of(modalID).hide()
                   }}
                   buttonPrimaryAction={() => {
                       this.runSurvey(srwID, modalID);
                   }}>
                <>
                    <Preloader showPreloader={this.state.runningSurveyPreloader}>
                        <p style={{textAlign: 'left'}}>
                            Operacja generowania linku do ankiety spowoduje aktywowanie ankiety dla Państwa szkoły. W dalszym ciągu będziecie mogli Państwo jednak zrezygnować z
                            realizacji badania w dowolnym momencie.
                        </p>
                        <p style={{textAlign: 'left'}}>
                            Po aktywowaniu badania uzyskacie Państwo możliwość zapoznania się z zawartością ankiety oraz otrzymacie link który będziecie mogli udostępnić uczniom w
                            celu wypełnienia ankiety
                        </p>
                    </Preloader>
                </>
            </Modal>
        );
        return modal;
    }

    /**
     *
     * @param numItems
     * @return {[{label: string, value: number}]}
     */
    getRandomArray(numItems) {
        let names = '1234ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let data = [];
        for (let i = 0; i < numItems; i++) {
            data.push({
                label: names[i],
                value: Math.round(20 + 80 * Math.random())
            });
        }
        return data;
    }

    toDataURL(canvas) {
        return canvas.toDataURL('image/png');
    }

    savePdf = () => {
        const doc = new jsPDF();

        doc.text("Oto nasz raport!", 10, 10);
        doc.addImage(this.toDataURL(this.barChartRef.current.canvasRef.current), 'image/png', 0, 100, 100, 100);
        doc.save("raport.pdf");

    }

    getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    loadStatsData = survey => {
        const surveyState = State.getSurveyState(this.props)
        if (!this.state.statsDataLoaded && !this.statsLoadingProgress) {
            this.statsLoadingProgress = true;
            UpdateQuestsItemsCols.run(this.props.dispatch, survey.params.hash, surveyState.lastUpdateLabelsTime(survey.params.hash), () => {
                UpdateStats.run(this.props.dispatch, survey.params.hash, surveyState.lastUpdateStatsTime(survey.params.hash), () => {
                    const surveyStat = surveyState.surveys[survey.params.hash];
                    const stats = surveyStat ? surveyStat.stats : null;
                    const quests = surveyStat ? surveyStat.quests : null;
                    const cols = surveyStat ? surveyStat.cols : null;
                    const items = surveyStat ? surveyStat.items : null;
                    const chartDataCollection = {};
                    if (stats && quests && items && cols) {
                        stats.forEach(stat => {
                            const count = stat.count;
                            const data = {
                                label: surveyStat.itemHTML(stat.itemID) + ' - ' + count + '',
                                value: count
                            }
                            let chartID;
                            let chartTitle;
                            const questHTML = surveyStat.questHTML(stat.questID);
                            const rawQuestText = surveyStat.questText(stat.questID);
                            let rawColText = null;
                            if (questHTML) {
                                if (stat.colID == null) {
                                    chartID = String(stat.questID);
                                    chartTitle = '<div style="display: block; text-align: center; font-weight: normal;"><strong>Kod pytania ' + stat.questRecord + '</strong><br/>' + questHTML + '</div>';
                                } else {
                                    rawColText = surveyStat.colText(stat.colID);
                                    chartID = String(stat.questID) + '-' + String(stat.colID);
                                    chartTitle = '<div style="display: block; text-align: center; font-weight: normal; margin-bottom: 1rem;">' +
                                        '<strong>Kod pytania ' + stat.questRecord + '</strong>' +
                                        '<small>' + questHTML + "</small>" +
                                        "<strong>" + surveyStat.colHTML(stat.colID) + '</strong>' +
                                        '</div>';
                                }
                            } else {
                                chartID = null;
                                chartTitle = '...';
                            }
                            if (chartID) {
                                let chartData = chartDataCollection[chartID];
                                if (!chartData) {
                                    const dataTable = [];
                                    dataTable.push(data);
                                    chartData = new ChartData(chartTitle, dataTable);
                                    if (rawColText) {
                                        chartData.shortTitle = 'P.' + stat.questRecord + '. ' + '<small>' + rawQuestText + '</small><br/>' + rawColText + '<br/><br/>';
                                    } else {
                                        chartData.shortTitle = 'P.' + stat.questRecord + '. ' + rawQuestText + '<br/><br/>';
                                    }
                                } else {
                                    let dataTable = chartData.data;
                                    if (!dataTable) {
                                        dataTable = [];
                                    }
                                    dataTable.push(data);
                                    chartData.data = dataTable;
                                }
                                chartDataCollection[chartID] = chartData;
                            }
                        })
                        this.setState({statsDataLoaded: true, chartDataCollection})
                        this.statsLoadingProgress = false;
                    }
                });
            });
        }
    }

    nextChart = () => {
        const size = Object.entries(this.state.chartDataCollection).length;
        const updatedIndex = this.state.chartIndexShown + 1;
        if (updatedIndex < size) {
            this.setState({chartIndexShown: updatedIndex})
        }
    }

    prevChart = () => {
        const updatedIndex = this.state.chartIndexShown - 1;
        if (updatedIndex >= 0) {
            this.setState({chartIndexShown: updatedIndex})
        }
    }

    firstChart = () => {
        this.setState({chartIndexShown: 0})
    }

    lastChart = () => {
        this.setState({chartIndexShown: Object.entries(this.state.chartDataCollection).length - 1})
    }

    gotoChart = index => {
        if (index >= 0 && index < Object.entries(this.state.chartDataCollection).length) {
            this.setState({chartIndexShown: index})
        }
    }

    toggleChartNavigationExpanded = () => {
        const oldValue = this.state.chartNavigationExpanded;
        this.setState({chartNavigationExpanded: !oldValue})
    }

    /**
     *
     * @param {string} modalID
     * @param {Survey} survey
     * @return {JSX.Element}
     */
    generateStatsModal = (modalID, survey) => {
        const charts = [];
        const chartNavigation = [];

        if (this.state.statsDataLoaded) {
            let index = 0;
            for (const [chartID, chartData] of Object.entries(this.state.chartDataCollection)) {
                if (this.state.chartIndexShown === index) {
                    charts.push(
                        <React.Fragment key={chartID}>
                            <Text style={{paddingTop: '1rem', fontSize: '1rem'}} color={'#1a1d3b'}>
                                <div dangerouslySetInnerHTML={{__html: chartData.title}}/>
                            </Text>
                            <BarChart data={chartData.data} title={"Liczba odpowiedzi"} color="#49ad5c" width={"100%"} height={"200px"} maxY={chartData.maxValue()}/>
                            {/* <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>*/}
                        </React.Fragment>
                    );
                    const parameter = index;
                    chartNavigation.push(
                        <div style={{display: 'block', cursor: 'Pointer', fontSize: '1rem'}} key={chartID} onClick={() => this.gotoChart(parameter)}>
                            <Text style={{fontSize: '1rem'}} color='#090909'>
                                <ScrollToMeOnRender/>
                                <strong>
                                    <div dangerouslySetInnerHTML={{__html: chartData.shortTitle}}/>
                                </strong>
                            </Text>
                        </div>
                    );
                } else {
                    const parameter = index;
                    chartNavigation.push(
                        <div style={{display: 'block', cursor: 'Pointer', fontSize: '1rem'}} key={chartID} onClick={() => this.gotoChart(parameter)}>
                            <Text style={{fontSize: '1rem'}} color='#090909'>
                                <div dangerouslySetInnerHTML={{__html: chartData.shortTitle}}/>
                            </Text>
                        </div>
                    );
                }
                index++;
            }
        }

        const modal = (
            <Modal id={modalID} showModalButtonText="Więcej statystyk" title={survey.surName} showModalButtonWidth="11rem"
                   buttonSecondary="Zamknij"
                   buttonSecondaryAction={() => {
                       ModalHandler.of(modalID).hide()
                   }}
                /*buttonPrimary="Pobierz raport"
                buttonPrimaryAction={this.savePdf}*/
            >
                <div style={{minHeight: '90%'}}>
                    <Preloader showPreloader={!this.state.statsDataLoaded} text="Pobieranie danych"
                               timeoutText="Operacja zakończona niepowodzeniem. Proszę uruchomić na komputerze/na innej przeglądarce">
                        {/*<Row>
                            <Col width={12}>
                                <div style={{maxHeight: '10rem', height: '10rem', overflow: 'auto'}}>
                                {chartNavigation}
                                </div>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col width={12}>
                                {charts}
                                {/*<BarChart data={this.getRandomArray(4)} title="Wykres" color="#3E517A" ref={this.barChartRef}/>*/}

                            </Col>
                        </Row>
                        <Row>
                            <Col width={12} align="center">
                                <Button size={1} color="primary" fixedWidth="6rem" onClickAction={this.firstChart}>&lt;&lt;&lt;</Button>
                                <Button size={1} color="primary" fixedWidth="6rem" onClickAction={this.prevChart}>&lt;</Button>
                                <Button size={1} color="primary" fixedWidth="6rem" onClickAction={this.nextChart}>&gt;</Button>
                                <Button size={1} color="primary" fixedWidth="6rem" onClickAction={this.lastChart}>&gt;&gt;&gt;</Button>
                                <Button size={1} color={this.state.chartNavigationExpanded ? 'secondary' : 'primary'}
                                        dataToggle={"collapse"} dataTarget={"#collapseExample"}
                                        onClickAction={this.toggleChartNavigationExpanded}>
                                    {this.state.chartNavigationExpanded ? 'Lista pytań <<<' : 'Lista pytań >>>'}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col width={12}>
                                <p>

                                </p>
                                <div className="collapse" id="collapseExample">
                                    <div className="card card-body">
                                        <div style={{
                                            maxHeight: '12rem',
                                            height: '12rem',
                                            overflow: 'auto',
                                            backgroundColor: '#edfaff',
                                            padding: '0.5rem',
                                            border: '1px solid #e1f5fc'
                                        }}>
                                            {chartNavigation}
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Preloader>
                </div>
            </Modal>
        );

        ModalHandler.of(modalID).onShow(() => {
            this.loadStatsData(survey);
        });
        return modal;
    }


    toggleSurveyDetailsExpanded = srwID => {
        let expansionState = {...this.state.surveyDetailsExpanded};
        let expanded = expansionState[srwID];
        if (expanded === undefined || expanded == null) {
            expanded = false;
        }
        expansionState[srwID] = !expanded;
        this.setState({
            surveyDetailsExpanded: expansionState
        });
    }

    loadQuotes = () => {
        const lastQuotesLoadTime = this.state.lastQuotesLoadTime;
        const now = new Date().getTime();
        if (now - lastQuotesLoadTime > 30000) {
            this.state.surveys.forEach(/** @param {Survey} survey*/survey => {
                if (survey.params) {
                    ApiEngine.getQuoteDone(survey.params.hash, result => {
                        const quotes = {...this.state.quotes}
                        quotes[survey.params.hash] = {
                            done: result.done,
                            notDone: result.notDone
                        }
                        this.setState({quotes, lastQuotesLoadTime: now});
                    }, errors => {
                        Logger.error(errors);
                    });
                }
            });
        }

    }

    redirect(menuItem) {
        this.props.history.push(menuItem.link);
        SetActiveMenuItemAction.run(this.props.dispatch, State.getProfileState(this.props).activeMenuItem, menuItem.label);
    }

    renderSurveysTable = () => {
        const profileState = State.getProfileState(this.props);
        const surveyRows = this.state.surveys.map(
            /**
             * @param {Survey} survey
             * @return {JSX.Element}
             */
            survey => {


                const modalID = 'surveyParamsModal_' + survey.srwID;

                /* /!**
                  * @type {SurveyParams}
                  *!/
                 const surveyParams = this.state.surveyParamsMap[survey.srwID];
                 let modal;
                 if (surveyParams.id) {
                     modal = this.generateEditingParamsModal(modalID, survey.srwID, surveyParams, 'Edytuj', 'Zapisz');
                 } else {
                     modal = this.generateEditingParamsModal(modalID, survey.srwID, surveyParams, 'Uruchom dla szkoły', 'Uruchom badanie');
                 }*/

                const detailsExpanded = this.state.surveyDetailsExpanded[survey.srwID];

                let surveyDetails;
                let detailsButton;

                detailsButton = (
                    <Button size={1} fixedWidth="11rem" onClickAction={() => {
                        this.toggleSurveyDetailsExpanded(survey.srwID)
                    }}>{detailsExpanded ? '<<< Ukryj' : 'Rozwiń >>>'}</Button>
                );

                const reportFileName = '/report/s231/raport_' + profileState.eduInstitution.id + '_' + survey.params.hash + '.pdf';


                if (detailsExpanded) {
                    surveyDetails = (
                        <tr>
                            <td colSpan={3} style={{borderTop: 0}}>
                                <Section padding="1rem 0">
                                    {/*<Row>
                                        <Col width={12} align="center">
                                            <Button href={survey.testLink} size={3} color='primary' fixedWidth='20rem' font="Roboto">
                                                Testowy podgląd ankiety
                                            </Button>
                                        </Col>
                                    </Row>*/}
                                    {!survey.isRun() &&
                                    <>
                                        <Preloader showPreloader={this.state.runningSurveyPreloader}>
                                            <Row>
                                                <Col width={6} align="justify" paddingTop='2rem'>
                                                    <Text font="Roboto" fontSize={"0.8rem"}>
                                                        Aktywowanie ankiety spowoduje wygenerowanie linku do wypełniania ankiety. Jednak W dowolnym momencie
                                                        będziecie mogli Państwo zrezygnować z realizacji badania.
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '2rem'}}>
                                                <Col width={12} align="center">
                                                    {/*{this.generateRunSurveyModal(modalID, survey.srwID)}*/}
                                                    <Button onClickAction={() => this.runSurvey(survey.srwID, modalID)} size={3} color='primary' fixedWidth='20rem' font="Roboto">
                                                        Aktywowanie ankiety
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Preloader>
                                    </>

                                    }
                                    {survey.isRun() &&
                                    <>
                                        {/*<Row>
                                            <Col width={12} paddingBottom="2rem" paddingTop="1rem">
                                                <Text font="Roboto">
                                                    <strong>Dedykowany link do ankiety:</strong>
                                                </Text>
                                                <Text font="Roboto" fontSize="0.8rem">
                                                    Link ten należy skopiować i udostępnić grupie docelowej. Sugerujemy wykorzystać do tego dziennik elektroniczny,
                                                    stronę internetową szkoły, portale społecznościowe lub inne formy komunikacji między szkołą
                                                    a uczniami/rodzicami. Poprzez ten link należy wypełniać ankietę, a jej wyniki zostaną automatycznie zapisane w systemie.
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row style={{border: '1px solid #333'}}>
                                            <Col width={12}>
                                                <div style={{display: 'table-cell', height: '4rem', verticalAlign: 'middle'}}>
                                                    <Text font="Roboto" style={{margin: 'auto'}}>
                                                        <span style={{color: 'black', marginRight: '1rem'}}><strong>{survey.params.startLink}</strong></span>
                                                        <Button size={1} color="danger" copyToClipboardText={survey.params.startLink}>
                                                            Skopiuj do schowka
                                                        </Button>
                                                    </Text>
                                                </div>
                                            </Col>
                                        </Row>*/}
                                        <Row style={{paddingTop: '2rem'}}>
                                            <Col align="left" width={4}>
                                                <Text font="Roboto">
                                                    Liczba wypełnionych ankiet:
                                                    <span style={{color: 'black', display: 'inline-block', marginLeft: '1rem', fontSize: '2rem'}}>
                                                        <strong>{this.state.quotes && this.state.quotes[survey.params.hash] ? this.state.quotes[survey.params.hash].done : '--'}</strong>
                                                    </span>
                                                </Text>
                                            </Col>
                                            <Col align="left" width={8}>
                                                <Row>
                                                    <Col width={4}>
                                                        {this.generateStatsModal('survey' + survey.params.hash, survey)}
                                                    </Col>
                                                    <Col width={8}>
                                                        {this.state.quotes && this.state.quotes[survey.params.hash] && this.state.quotes[survey.params.hash].done >= 10 && <Button fixedWidth="11rem" size={1} href={reportFileName}>Raport końcowy</Button>}
                                                        {(!this.state.quotes || !this.state.quotes[survey.params.hash] || this.state.quotes[survey.params.hash].done < 10)
                                                        &&
                                                            <>
                                                                <Button disabled={true} fixedWidth="11rem" size={1}>Raport końcowy</Button>
                                                                <div style={{marginLeft: '0.8rem'}}>
                                                                    <Text font="Roboto" fontSize="0.8rem"><strong>Raport dostępny w przypadku wypełnienia więcej niż 10 ankiet</strong></Text>
                                                                </div>
                                                            </>
                                                        }
                                                       {/* <Button fixedWidth="11rem" size={1} href={reportFileName}>Raport końcowy</Button>
                                                        <div style={{marginLeft: '0.8rem'}}>
                                                            <Text font="Roboto" fontSize="0.8rem"><strong>Uwaga!!! Raport końcowy będzie dostępny od 25.06.2021</strong></Text>
                                                        </div>*/}
                                                        {/*color="#DE781F"*/}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {/*<Row>
                                            <Col align="left" width={4}>
                                                <Text font="Roboto">
                                                    Liczba otwartych ankiet:
                                                </Text>
                                            </Col>
                                            <Col width={8}>
                                                <div style={{display: 'inline-block', width: '5rem'}}>
                                                    <Text style={{textAlign: 'right'}} font="Roboto">
                                                        <span
                                                            style={{color: 'black'}}><strong>{this.state.quotes && this.state.quotes[survey.params.hash] ? this.state.quotes[survey.params.hash].notDone : '--'}</strong></span>
                                                    </Text>
                                                </div>
                                            </Col>
                                        </Row>*/}
                                        {/*<Row style={{marginBottom: '2rem'}}>
                                            <Col width={12}>
                                                <Text font="Roboto" fontWeight="bold" color="green" style={{cursor: 'pointer', display: 'inline-block'}}><p
                                                    onClick={() => this.redirect(Msg.of().menu.statistics)}>Więcej statystyk...</p></Text>
                                            </Col>
                                        </Row>*/}
                                    </>
                                    }
                                </Section>
                            </td>
                        </tr>
                    );
                } else {
                    surveyDetails = null;
                }

                /*else {
                    surveyDetails = null;
                    detailsButton = this.generateRunSurveyModal(modalID, survey.srwID);
                }*/
                return (
                    <React.Fragment key={survey.surID}>
                        <tr>
                            <td style={{
                                verticalAlign: 'middle',
                                fontWeight: survey.isRun() ? 'bold' : 'normal',

                            }}>
                                <Text font="Roboto">
                                    {survey.surName}
                                </Text>
                            </td>
                            <td style={{verticalAlign: 'middle', textAlign: 'right'}}>

                                {detailsButton}
                                {/*{modal}*/}
                                {/*<Modal id={modalID} showModalButtonText="Uruchom dla szkoły" title="Parametry badania" showModalButtonWidth="11rem"
                                   buttonPrimary="Uruchom badanie" buttonPrimaryAction={() => {
                                this.saveSurveyParamsAction(survey.srwID, modalID)
                            }}>
                                <>
                                    {SurveyParamsForm.get(surveyParams, 500, this.generatorOfHandleSurveyParamsChangeFunction(survey.srwID), this.state.validationResult)}
                                </>
                            </Modal>*/}
                            </td>
                        </tr>
                        {surveyDetails}
                    </React.Fragment>
                );
            });
        const surveysTableHtml = (
            <table className="table table-sm">
                {/*<thead>
                <tr>
                    <th style={{borderTop: 0}}>Ankieta</th>
                    <th style={{borderTop: 0}} className="align-center">Operacje</th>
                </tr>
                </thead>*/}
                <tbody>
                {surveyRows}
                </tbody>
            </table>
        );
        return surveysTableHtml;
    }


    render() {
        const profileState = State.getProfileState(this.props);
        const surveyState = State.getSurveyState(this.props);
        if (profileState.isLogged()) {
            return (
                <>
                    <ScrollToTopOnMount/>
                    <Subpage title={Msg.of().menu.surveys.label} small={true}>
                        <Row>
                            <Preloader showPreloader={!this.state.surveysInitialized}>
                                <Col width={12} style={{minHeight: '31rem'}}>
                                    {this.renderSurveysTable()}
                                </Col>
                            </Preloader>
                        </Row>
                    </Subpage>
                </>
            );
        } else {
            return <PageLoginUser/>
        }
    }
}


const PageSurveyTemplatesConnectedToRedux = connect(State.builder().profile().survey().mapStateToProps(), State.mapDispatchToProps())(PageSurveys)

export default withRouter(PageSurveyTemplatesConnectedToRedux);